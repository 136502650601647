body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  background: linear-gradient(90deg, #19191c, rgba(25, 25, 28, 0) 39.82%), linear-gradient(174deg, #19191c -8.75%, rgba(25, 25, 28, 0) 33.45%), linear-gradient(.86turn, #191a1c, #191a1c 0, rgba(25, 25, 28, 0) 15.21%), conic-gradient(from 182.2deg at 55.19% 72.68%, #19191c -97.5deg, #371d9f 6.27deg, #19191c 22.42deg, #543fef 49.12deg, #2a0dff 79.97deg, #19191c 92.76deg, #19191c 128.57deg, #153cff 154.67deg, #253647 226.37deg, #19191c 262.5deg, #371d9f 366.27deg);
}

.background {
  background-color: #2c3544 !important;
}

.dark-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1e1e1e !important;
  text-align: left;
  font-family: Arial, sans-serif;
}

.dark-table * {
  font-size: 1rem !important;
}

.dark-table th {
  color: #ffffff;
}

.dark-table th, .dark-table td {
  border: 1px solid #333333;
  padding: 6px;
}

.dark-table thead tr:nth-child(odd) th {
  background-color: #333333 !important;
}

.dark-table thead tr:nth-child(even) th {
  background-color: #2a2a2a !important;
}

.dark-table tbody tr:nth-child(odd) {
  background-color: #2a2a2a !important;
}

.dark-table tbody tr:nth-child(even) {
  background-color: #333333 !important;
}

.dark-table tbody tr:hover {
  background-color: #444444 !important;
  cursor: pointer;
}

.dark-table a:hover {
  color: #9bacff !important;
}

.dark-table td {
  font-size: 0.8rem;
}

.dark-table caption {
  caption-side: top;
  color: #ffffff !important;
  font-size: 1.2rem;
  padding: 10px 0;
}

::-webkit-scrollbar {
  width: 18px;
  background-color: #182131;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.imgSetup{
  width: 400px;
}
.imgSetupBottom{
  width: 800px;
}
.gitImg{
  width: 38px;
}